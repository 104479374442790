import React from 'react';
import './policy.css';

const Policy = () => {
  return (
    <div className='policy-container'>
      <h1>Integritetspolicy för Skola77</h1>
      <p><strong>Senast uppdaterad:</strong> [2024-09-30]</p>

      <p>
        Vi på <strong>Skola77</strong> värnar om din integritet och strävar efter att skydda dina personuppgifter på ett ansvarsfullt sätt. Denna integritetspolicy beskriver hur vi samlar in, använder och skyddar dina uppgifter när du använder vår tjänst.
      </p>

      <h2>1. Vilka uppgifter vi samlar in</h2>
      <p>
        När du använder <strong>Skola77</strong> kan vi komma att samla in följande personuppgifter:
      </p>
      <ul>
        <li><strong>E-postadress:</strong> Används för att skapa och underhålla ditt konto, samt för att skicka viktig information.</li>
        <li><strong>Användarnamn:</strong> Används som identifierare i systemet.</li>
        <li><strong>Krypterat lösenord:</strong> Ditt lösenord är änd-till-änd-krypterat för att skydda din säkerhet.</li>
      </ul>

      <h2>2. Hur vi använder dina uppgifter</h2>
      <p>Vi använder de uppgifter vi samlar in för följande ändamål:</p>
      <ul>
        <li><strong>För att tillhandahålla tjänsten:</strong> Vi använder dina uppgifter för att skapa och underhålla ditt konto och möjliggöra användningen av tjänstens funktioner.</li>
        <li><strong>För kommunikation:</strong> Vi kan komma att använda din e-postadress för att skicka dig uppdateringar, supportinformation eller andra viktiga meddelanden om tjänsten.</li>
        <li><strong>För att förbättra tjänsten:</strong> Uppgifter kan också användas för att analysera hur vår tjänst används och för att utveckla nya funktioner.</li>
      </ul>

      <h2>3. Rättslig grund för behandling</h2>
      <p>
        Behandlingen av dina personuppgifter grundar sig på ditt <strong>samtycke</strong> när du skapar ett konto hos oss och använder vår tjänst. Du har rätt att när som helst återkalla ditt samtycke genom att kontakta oss eller radera ditt konto.
      </p>

      <h2>4. Hur vi skyddar dina uppgifter</h2>
      <p>
        Vi tar säkerheten för dina personuppgifter på allvar och vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda dem. Dina lösenord lagras i krypterad form och all kommunikation med tjänsten sker via <strong>SSL-kryptering</strong> (HTTPS). Vi begränsar också åtkomst till dina uppgifter till enbart de personer och system som behöver dem för att tillhandahålla tjänsten.
      </p>

      <h2>5. Hur länge vi sparar dina uppgifter</h2>
      <p>
        Vi sparar dina personuppgifter så länge ditt konto är aktivt eller så länge det krävs för att kunna tillhandahålla tjänsten. Om du väljer att avsluta ditt konto raderar vi dina uppgifter, såvida vi inte är skyldiga enligt lag att behålla dem under en längre period.
      </p>

      <h2>6. Dina rättigheter</h2>
      <p>Som användare av <strong>Skola77</strong> har du följande rättigheter:</p>
      <ul>
        <li><strong>Rätt till tillgång:</strong> Du kan begära en kopia av de personuppgifter vi har om dig.</li>
        <li><strong>Rätt till rättelse:</strong> Du kan begära att felaktiga eller ofullständiga uppgifter rättas.</li>
        <li><strong>Rätt till radering:</strong> Du kan begära att vi raderar dina personuppgifter (rätten att bli glömd), såvida vi inte är skyldiga att behålla dem enligt lag.</li>
        <li><strong>Rätt till dataportabilitet:</strong> Du kan begära att få dina uppgifter i ett strukturerat, allmänt använt och maskinläsbart format.</li>
        <li><strong>Rätt att återkalla samtycke:</strong> Du kan när som helst återkalla ditt samtycke till behandlingen av dina personuppgifter.</li>
      </ul>

      <h2>7. Delning av personuppgifter</h2>
      <p>
        Vi delar inte dina personuppgifter med tredje part, förutom när det krävs enligt lag eller för att tillhandahålla tjänsten (t.ex. med hosting-leverantörer). I dessa fall säkerställer vi att sådana tredjepartsleverantörer följer GDPR och skyddar dina uppgifter på ett adekvat sätt.
      </p>

      <h2>8. Cookies och spårning</h2>
      <p>
        Vi använder cookies för att hålla dig inloggad och för att förbättra din upplevelse. Du kan hantera och blockera cookies via din webbläsares inställningar.
      </p>

      <h2>9. Ändringar i integritetspolicyn</h2>
      <p>
        Vi kan uppdatera denna integritetspolicy från tid till annan. När vi gör betydande ändringar meddelar vi dig via e-post eller genom att publicera en uppdaterad version på vår webbplats. Den senaste versionen finns alltid tillgänglig på vår webbplats.
      </p>

      <h2>10. Kontaktinformation</h2>
      <p>
        Om du har frågor om denna integritetspolicy eller om hur vi behandlar dina personuppgifter, vänligen kontakta oss på:
      </p>
      <p>
        E-post: [<a href='mailto:support@skola77.com'>support@skola77.com</a>] <br />

      </p>
    </div>
  );
};

export default Policy;
