import React from 'react';
import './policy.css';
const TermsOfService = () => {
  return (
    <div className="policy-container">
      <h1>Användarvillkor för Skola77</h1>
      <p><strong>Senast uppdaterad:</strong> [2024-09-30]</p>

      <p>
        Välkommen till <strong>Skola77</strong>. Genom att använda vår tjänst godkänner du dessa
        användarvillkor. Läs noggrant igenom dessa villkor innan du använder tjänsten.
      </p>

      <h2>1. Användning av tjänsten</h2>
      <p>
        Du får använda <strong>Skola77</strong> för personligt bruk eller för att hantera bordsplaceringar inom utbildning och evenemang. Du får inte använda tjänsten på ett sätt som bryter mot lagar eller tredje parts rättigheter.
      </p>

      <h2>2. Kontoregistrering</h2>
      <p>
        För att använda <strong>Skola77</strong> behöver du skapa ett konto. Du är
        ansvarig för att hålla dina kontouppgifter säkra och konfidentiella. Om du misstänker att någon annan har åtkomst till ditt konto, måste du omedelbart kontakta oss.
      </p>

      <h2>3. Ändringar av tjänsten</h2>
      <p>
        Vi förbehåller oss rätten att ändra, uppdatera eller avsluta tjänsten när som helst utan
        föregående meddelande. Vi strävar efter att hålla tjänsten tillgänglig, men kan inte garantera att den är tillgänglig utan avbrott.
      </p>

      <h2>4. Ansvarsbegränsning</h2>
      <p>
        <strong>Skola77</strong> tillhandahålls "som den är" och vi lämnar inga garantier för dess funktion, tillgänglighet eller tillförlitlighet. Vi ansvarar inte för direkta, indirekta, eller oförutsedda skador som uppstår vid användning av tjänsten.
      </p>

      <h2>5. Immateriella rättigheter</h2>
      <p>
        Alla rättigheter till innehåll och programvara som används i <strong>Skola77</strong> tillhör oss eller våra licensgivare. Du har ingen rätt att kopiera, modifiera eller distribuera delar av tjänsten utan uttryckligt skriftligt tillstånd.
      </p>

      <h2>6. Upphörande</h2>
      <p>
        Vi förbehåller oss rätten att avsluta eller begränsa din åtkomst till tjänsten om du bryter
        mot dessa användarvillkor eller om vi misstänker olaglig aktivitet på ditt konto.
      </p>

      <h2>7. Ändringar av användarvillkor</h2>
      <p>
        Vi kan uppdatera dessa användarvillkor från tid till annan. När vi gör betydande ändringar meddelar vi dig via e-post eller genom att publicera en uppdaterad version på vår webbplats. Genom att fortsätta använda tjänsten efter att ändringar har trätt i kraft, godkänner du de uppdaterade villkoren.
      </p>

      <h2>8. Kontaktinformation</h2>
      <p>
        Om du har frågor om dessa användarvillkor, vänligen kontakta oss på:
      </p>
      <p>
       E-post: [<a href='mailto:support@skola77.com'>support@skola77.com</a>] <br />
      </p>
    </div>
  );
};

export default TermsOfService;
