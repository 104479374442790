const NoPage = () => {
    return (
      <div>
        
    <h1>404</h1>
    <h2>Sidan du söker verkar inte finnas. Kolla i addressfönstret efter felstavningar eller liknande.</h2>
    </div> 
    )
  };
  
  export default NoPage